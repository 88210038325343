body{
    margin: 0;
    font-family: sans-serif;
    padding: 70px 70px 70px 70px;
    background-color: rgba(64, 224, 208, 0.548);
}

.header{
    background-color: chocolate;
    height: 50px;
    font-size: 2rem;
    color: white;
    padding: 20px;
    text-align: center; 
    border-radius: 5px;  
}

.todoform{
    width: 100%;
    margin: auto;
    margin-top: 20px;
}

.todoinput{
    width: 100%;
    border: none;
    border-top: 1px solid whitesmoke;;
    border-bottom: 1px solid whitesmoke;
    font-size: 1.5rem;
    text-align: center;
    height: 50px; 
    border-radius: 5px;
    color:  rgba(211, 100, 20, 0.952);
}

.todoinput:hover{
    border-color:  rgba(211, 100, 20, 0.952);
}


.todobutton{
    width: 100%;
    border: none;
    height: 50px;
    background-color: rgba(211, 100, 20, 0.952);
    font-size: 1.2rem;
    color: white;
    margin-top: 20px;
    border-radius: 5px;
}

.todoitem{
    margin: auto;
    border: 1px solid whitesmoke;
    padding: 20px;
    margin-top: 20px;
    border: none;
    color: rgb(102, 15, 15);
}


.deletebutton{
    border: none;
    height: 50px;
    background-color: rgba(211, 100, 20, 0.952);
    color: white;
    border-radius: 2px;
    width: 100px;
}

